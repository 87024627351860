import React, { FC } from 'react';
import {
  Dialog,
  DialogProps,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogTitle,
  DialogTitleProps,
  Theme,
  makeStyles,
  Typography,
  IconButton,
  alpha,
} from '@material-ui/core';
import clsx from 'clsx';
import { Close } from '@material-ui/icons';

export interface IModalProps extends DialogProps {
  actions?: React.ReactNode;
  children: React.ReactNode;
  DialogActionProps?: DialogActionsProps;
  DialogContentProps?: DialogContentProps;
  DialogTitleProps?: DialogTitleProps;
  id?: string;
  onClose: () => void;
  showClose?: boolean;
  title?: string;
  variant?: 'default' | 'error';
}

export const Modal: FC<IModalProps> = (props) => {
  const {
    actions,
    children,
    DialogActionProps,
    DialogContentProps,
    DialogTitleProps,
    id,
    onClose,
    showClose = true,
    title,
    variant,
    ...modalProps
  } = props;

  const classes = modalStyles(props);

  const isErrorModal = props.variant && props.variant === 'error';

  return (
    <Dialog
      {...modalProps}
      aria-describedby={`${id}-description`}
      aria-labelledby={`${id}-title`}
      BackdropProps={{ className: classes.backdrop }}
      data-testid={id}
      className={
        variant && variant === 'error' ? classes.error : classes.dialog
      }
      onClose={onClose}
      PaperProps={{
        className: clsx(
          classes.paper,
          props.PaperProps && props.PaperProps.className
            ? props.PaperProps.className
            : ''
        ),
      }}
    >
      {(title || showClose) && (
        <DialogTitle
          id={`${id}-title`}
          className={classes.dialogTitle}
          disableTypography={true}
          {...DialogTitleProps}
        >
          {title && (
            <Typography className={classes.heading} variant="h6">
              {title}
            </Typography>
          )}
          {showClose && (
            <IconButton
              aria-label="Close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <Close className={classes.closeIcon} />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent
        className={classes.dialogContent}
        dividers={false}
        id={`${id}-description`}
        {...DialogContentProps}
      >
        {children}
      </DialogContent>
      {actions && (
        <DialogActions className={classes.dialogActions} {...DialogActionProps}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

const modalStyles = makeStyles<Theme, IModalProps>((theme) => {
  return {
    backdrop: {
      backgroundColor: alpha(theme.palette.background.default, 0.75),
    },
    closeButton: {
      padding: theme.spacing(1),
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.common.black,
      ['@media print']: {
        display: 'none',
      },
    },
    closeIcon: {
      height: theme.spacing(3),
      width: `${theme.spacing(3)}px !important`,
    },
    dialog: {
      ['@media print']: {
        position: 'static !important',
        '& > .MuiBackdrop-root': {
          backgroundColor: 'transparent',
        },
      },
    },
    dialogActions: {
      padding: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
      },
      ['@media print']: {
        paddingBottom: '0 !important',
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
      },
    },
    dialogContent: {
      padding: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        padding: (props) => theme.spacing(0, 4),
        '&:last-child': {
          paddingBottom: theme.spacing(4),
        },
      },
      ['@media print']: {
        overflow: 'visible',
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        '&:last-child': {
          paddingBottom: '0 !important',
        },
      },
    },
    dialogTitle: {
      minHeight: (props) => (props.title ? theme.spacing(7) : theme.spacing(5)),
      padding: theme.spacing(3, 3, 0, 3),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4, 4, 0, 4),
      },
      ['@media print']: {
        minHeight: '0 !important',
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        paddingTop: '0 !important',
      },
    },
    errorTitle: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      display: 'flex',
      alignItems: 'center',
    },
    heading: {
      marginRight: theme.spacing(2.5),
      textAlign: 'center',
    },
    paper: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      margin: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        margin: theme.spacing(4),
      },
      ['@media print']: {
        margin: 0,
      },
    },
  };
});
