import React, { useEffect, useState, FC } from "react";
import { isAndroid, isEdge, isIOS, isMobileSafari } from "react-device-detect";
import * as localForage from "localforage";
// components
import { Modal } from "../modal";
import { makeStyles, Typography } from "@material-ui/core";
import Step1IOS from "../../images/ios-step-1.jpg";
import Step2IOS from "../../images/ios-step-2.jpg";
import Step3IOS from "../../images/ios-step-3.jpg";
import Step1Android from "../../images/android-step-1.jpg";
import Step2Android from "../../images/android-step-2.jpg";
import Step3Android from "../../images/android-step-3.jpg";
import { MoreHoriz, MoreVert } from "@material-ui/icons";
import Share from "../../images/apple-share-blue-image.png";

export interface IInstallPrompt {
  name?: string;
  logoUrl: string;
  open?: boolean;
  setOpen?: (val: boolean) => void;
  backgroundColor?: string;
  autoClose?: boolean; // Uses local storage to prevent opening after close.
}

export const InstallPrompt: FC<IInstallPrompt> = ({
  name = "EA Mobile Benefits App",
  logoUrl,
  open,
  setOpen,
  backgroundColor,
  autoClose = true, // default uses local storage to prevent opening after close, if no prop provided
}) => {
  const classes = useStyles();
  const [isOpen, showModal] = useState<boolean>(false);

  // For testing mobile app locally, adjust below logic is need to test the share icon displayed in prompt
  const setShareIcon = () => {
    if (isMobileSafari) {
      return <img className={classes.shareImg} alt="share-icon" src={Share} />;
    }
    if (isEdge) {
      return <MoreHoriz className={classes.shareIcon} />;
    }
    return <MoreVert className={classes.shareIcon} />;
  };
  // For testing mobile app locally, adjust below logic is need to test the share wording displayed in prompt
  const setShareText = () => {
    if (isEdge) {
      return "Add to Phone";
    }
    return "Add to Home Screen";
  };
  const shareIcon = setShareIcon();
  const shareText = setShareText();
  const generatedContent = (
    <>
      <Typography component="h1" className={classes.subHeading}>
        Add a shortcut to this application on your home screen for quick and
        easy access in the future.
      </Typography>
      <Typography component="p" className={classes.subText}>
        Just Tap {shareIcon} then '{shareText}'
      </Typography>
    </>
  );

  const iosImageSteps = (
    <div>
      <img
        className={classes.instructionImage}
        src={Step1IOS}
        alt="It's simple to add this app to your home screen. Click on the add to home screen icon at the bottom of your phone's screen."
      />
      <img
        className={classes.instructionImage}
        src={Step2IOS}
        alt="Choose 'Add to home screen'."
      />
      <img
        className={classes.instructionImage}
        src={Step3IOS}
        alt="You're all set."
      />
    </div>
  );
  const androidImageSteps = (
    <div>
      <img
        className={classes.instructionImage}
        src={Step1Android}
        alt="It's simple to add this app to your home screen. Click on the add to home screen icon at the bottom of your phone's screen."
      />
      <img
        className={classes.instructionImage}
        src={Step2Android}
        alt="Choose 'Add to home screen'."
      />
      <img
        className={classes.instructionImage}
        src={Step3Android}
        alt="You're all set."
      />
    </div>
  );

  // For testing mobile app locally, adjust below logic is need to test content displayed in prompt
  const setPromptContent = () => {
    if (isMobileSafari) {
      return iosImageSteps;
    }
    if (isEdge) {
      return generatedContent; // use default prompt content to show specific wording for Edge
    }
    return androidImageSteps; // default messaging uses android images
  };

  const promptContent = setPromptContent();

  const checkToShowModal = async () => {
    const { navigator }: any = window;
    const showAddToHomeScreen = await localForage.getItem(
      "showAddToHomeScreen"
    );
    // Comment below for testing mobile app locally
    if (
      (autoClose &&
        (!showAddToHomeScreen || showAddToHomeScreen !== "false") &&
        !navigator.standalone &&
        (isIOS || isAndroid)) ||
      (!autoClose && !navigator.standalone && (isIOS || isAndroid))
    ) {
      showModal(true);
    }
    // Uncomment below for testing mobile app locally
    // if (
    //   (autoClose &&
    //     (!showAddToHomeScreen || showAddToHomeScreen !== "false") &&
    //     !navigator.standalone) ||
    //   (!autoClose && !navigator.standalone)
    // ) {
    //   showModal(true);
    // }
  };

  useEffect(() => {
    if (open === undefined) {
      checkToShowModal();
    }
  }, []);
  useEffect(() => {
    if (!!open) {
      checkToShowModal();
    }
  }, [open]);
  return (
    <Modal
      maxWidth="sm"
      open={isOpen}
      onClose={async () => {
        await localForage.setItem("showAddToHomeScreen", "false");
        showModal(false);
        if (!!setOpen) setOpen(false);
      }}
      title={`Add shortcut to ${name}`}
    >
      {logoUrl && (
        <div
          className={classes.imageWrap}
          style={{
            backgroundColor: backgroundColor ? backgroundColor : undefined,
          }}
        >
          <img alt={name} className={classes.logo} src={logoUrl} />
        </div>
      )}
      {promptContent}
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  subHeading: {
    textAlign: "center",
    marginTop: 5,
    marginBottom: 5,
  },
  subText: {
    display: "flex",
    background: "#f4f4f4",
    flex: "1 1 100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(0.5),
    "@media (min-width: 400px)": {
      flexDirection: "row",
    },
  },
  shareImg: {
    fontSize: 14,
    height: 30,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
  shareIcon: {
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
  },
  imageWrap: {
    marginRight: "auto",
    marginLeft: "auto",
    height: 100,
    marginBottom: theme.spacing(2),
    width: "100%",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    maxWidth: "100%",
    alignItems: "center",
  },
  logo: {
    height: "75%",
  },
  instructionImage: {
    marginTop: theme.spacing(5),
    maxWidth: "100%",

    "&&:first-child": {
      marginTop: theme.spacing(2),
    },
  },
}));
