import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { Loader, Message } from './components';
import {
  getMobileBusinessClient,
  getMobileBusinessClientCustomUrl,
} from './fetch';
import { IMobileBusinessClient } from './models';
import 'html5-device-mockups/dist/device-mockups.min.css';
import { IPhone6 } from 'react-device-mockups';
import { Content } from './components/content';

export const App = () => {
  const [mobileData, setMobileData] = useState<IMobileBusinessClient | null>(
    null
  );
  const [siteError, setSiteError] = useState<boolean>(false);
  let params = '';
  let clientId = 0;
  if (window.location.href.includes('employee-portal')) {
    const split = window.location.href.split('/');
    const lastPartOfUrl = split[split.length - 1];
    params =
      window.location.href.includes('localhost') ||
      window.location.href.includes('ngrok')
        ? `?ClassId=68&LocationId=198`
        : window.location.search;

    // clientID pulled from the URL or localhost will use hardcoded value
    // dev.mobilebenefitsapp.com/employee-portal/business-clients/2072?ClassId=68&LocationId=198
    clientId = window.location.href.includes('localhost')
      ? 2072
      : // grab the first of this split which is the clientID
        Number(lastPartOfUrl.split('?')[0]);
  } else {
    // grab the url after the domain https://dev.mobilebenefitsapp.com/coolbeans
    // url = coolbeans
    const url =
      window.location.href &&
      window.location.href.split('/') &&
      window.location.href.split('/')[3];
    params = url ? `?url=${url}` : '';
  }

  const handleData = (data: IMobileBusinessClient) => {
    if (data && data.images) {
      // build out the dynmic manifest.json for Android phones
      const myDynamicManifest = {
        short_name: data.businessClientName,
        name: data.businessClientName,
        icons: [
          {
            src: data.images.app_Logo_Android_Chrome_192x192,
            type: 'image/png',
            sizes: '192x192',
          },
          {
            src: data.images.app_Logo_Android_Chrome_512x512,
            type: 'image/png',
            sizes: '512x512',
          },
        ],
        start_url: '/',
        display: 'standalone',
        theme_color: data.hexColor,
        background_color: '#ffffff',
        source: '/',
      };
      const link = document.createElement('link');
      link.rel = 'manifest';
      link.id = 'manifest';
      const stringManifest = JSON.stringify(myDynamicManifest);
      link.setAttribute(
        'href',
        'data:application/json;charset=utf-8,' +
          encodeURIComponent(stringManifest)
      );
      // set the new link in the <head> link
      document.head.appendChild(link);

      return setMobileData(data);
    }
    return setSiteError(true);
  };

  const fetchMobileApp = async () => {
    if (clientId && params) {
      const data = await getMobileBusinessClient(clientId, params);
      return handleData(data);
    }
    if (params) {
      const data = await getMobileBusinessClientCustomUrl(params);
      return handleData(data);
    }
  };
  useEffect(() => {
    fetchMobileApp();
  }, []);

  const classes = useStyles({
    primaryColor: mobileData?.hexColor || '#fff',
  });
  const name = mobileData?.businessClientName
    ? mobileData?.businessClientName
    : 'Enrollment Alliance';

  const brokenLinks = mobileData?.links.filter((link) => !link.link);
  const hasOutDatedApp =
    brokenLinks &&
    brokenLinks.map((link) => {
      if (link.type === 'BenefitsInfo' || link.type === 'Calendar') {
        return link;
      }
    }).length > 0;
  const isMobile = useMediaQuery('(max-width: 960px)');
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{name}</title>
        {mobileData && (
          <>
            {/* Favorite icons */}
            <link
              href={`${
                mobileData.images.app_Logo_Favicon_16x16
              }?v=${new Date().toISOString()}`}
              rel="icon"
              sizes="16x16"
              type="image/png"
            />
            <link
              href={`${
                mobileData.images.app_Logo_Favicon_32x32
              }?v=${new Date().toISOString()}`}
              rel="icon"
              sizes="32x32"
              type="image/png"
            />

            {/* iOS icons */}
            <link
              href={`${
                mobileData.images.app_Logo_Apple_Touch_Icon_180x180
              }?v=${new Date().toISOString()}`}
              rel="apple-touch-icon"
              sizes="180x180"
            />

            {/* iOS startup images */}
            <link
              href={`${
                mobileData.images.app_Logo_Apple_Startup_Image_640x1136
              }?v=${new Date().toISOString()}`}
              media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
              rel="apple-touch-startup-image"
            />
            <link
              href={`${
                mobileData.images.app_Logo_Apple_Startup_Image_750x1334
              }?v=${new Date().toISOString()}`}
              media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
              rel="apple-touch-startup-image"
            />
            <link
              href={`${
                mobileData.images.app_Logo_Apple_Startup_Image_828x1792
              }?v=${new Date().toISOString()}`}
              media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
              rel="apple-touch-startup-image"
            />
            <link
              href={`${
                mobileData.images.app_Logo_Apple_Startup_Image_1242x2208
              }?v=${new Date().toISOString()}`}
              media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)"
              rel="apple-touch-startup-image"
            />
            <link
              href={`${
                mobileData.images.app_Logo_Apple_Startup_Image_1242x2688
              }?v=${new Date().toISOString()}`}
              media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
              rel="apple-touch-startup-image"
            />
            <link
              href={`${
                mobileData.images.app_Logo_Apple_Startup_Image_828x1792
              }?v=${new Date().toISOString()}`}
              media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
              rel="apple-touch-startup-image"
            />
            <link
              href={`${
                mobileData.images.app_Logo_Apple_Startup_Image_1536x2048
              }?v=${new Date().toISOString()}`}
              media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)"
              rel="apple-touch-startup-image"
            />
            <link
              href={`${
                mobileData.images.app_Logo_Apple_Startup_Image_1668x2224
              }?v=${new Date().toISOString()}`}
              media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)"
              rel="apple-touch-startup-image"
            />
            <link
              href={`${
                mobileData.images.app_Logo_Apple_Startup_Image_1668x2388
              }?v=${new Date().toISOString()}`}
              media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)"
              rel="apple-touch-startup-image"
            />
            <link
              href={`${
                mobileData.images.app_Logo_Apple_Startup_Image_2048x2732
              }?v=${new Date().toISOString()}`}
              media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)"
              rel="apple-touch-startup-image"
            />

            {/* Microsoft tiles */}
            <meta
              name="msapplication-TileColor"
              content={mobileData.hexColor || ''}
            />
            <link
              rel="manifest"
              href={`data:application/xml,<?xml version="1.0" encoding="utf-8"?><browserconfig><msapplication><tile><square150x150logo src="${
                mobileData.images.app_Logo_Microsoft_Tile_150x150
              }?v=${new Date().toISOString()}"/><TileColor>${
                mobileData.hexColor || ''
              }</TileColor></tile></msapplication></browserconfig>`}
            />

            {/* Safari tabs */}
            <meta name="theme-color" content={mobileData.hexColor || ''} />
            {/* Enable PWA */}
            <meta content={name} name="application-name" />
            <meta content="yes" name="apple-mobile-web-app-capable" />
            <meta
              content="default"
              name="apple-mobile-web-app-status-bar-style"
            />
            <meta content={name} name="apple-mobile-web-app-title" />
          </>
        )}
      </Helmet>
      {!mobileData && !siteError && (
        <div className={classes.loaderWrapper}>
          <Loader position="centered" size="large" />
        </div>
      )}
      {mobileData &&
        !siteError &&
        (isMobile ? (
          <Content
            mobileData={mobileData}
            siteError={siteError}
            hasOutDatedApp={hasOutDatedApp}
          />
        ) : (
          <div className={classes.preview}>
            <IPhone6 orientation="portrait">
              <Content
                mobileData={mobileData}
                siteError={siteError}
                hasOutDatedApp={hasOutDatedApp}
              />
            </IPhone6>
          </div>
        ))}
      {(siteError || (mobileData && !mobileData.mobileAppEnabled)) && (
        <Message message="Service is currently not available" />
      )}
    </HelmetProvider>
  );
};

const useStyles = makeStyles(() => ({
  '@global': {
    body: {
      margin: 0,
    },
  },
  wrapper: {
    maxWidth: 450,
    margin: '0 auto',
  },
  col: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    boxShadow: '0px 2px 7.2px 0px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#262626',
    padding: '1rem 0',
  },
  logo: {
    width: '60%',
    '@media (min-width: 400px)': {
      width: '50%',
    },
  },

  loaderWrapper: {
    marginTop: 50,
    '& p, & div': {
      color: ({ primaryColor }: { primaryColor: string }) => primaryColor,
    },
  },
  name: {
    marginTop: 5,
  },
  appBackground: {
    padding: 0,
    maxWidth: '320px',
    height: '100%',
    textAlign: 'center',
  },
  preview: {
    '& .device-wrapper': {
      marginTop: '1rem',
      margin: '0 auto',
      opacity: '1',
      width: '300px',
      maxWidth: '300px',
    },
    '& .screen': {
      overflow: 'auto',
      pointerEvents: 'all',
      background: '#fff',
    },
  },
}));
