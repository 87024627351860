import { FC, Fragment } from 'react';
import { IMobileBusinessClient, IMobileDataLink } from '../../models';
import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
interface IContent {
  mobileData: IMobileBusinessClient | null;
  siteError: boolean;
  hasOutDatedApp: boolean | undefined;
}

import { getMobileAppLink } from '../../helpers/applink';

export const Content: FC<IContent> = ({
  mobileData,
  siteError,
  hasOutDatedApp,
}) => {
  const classes = useStyles({
    primaryColor: mobileData?.hexColor || '#fff',
  });

  const name = mobileData?.businessClientName
    ? mobileData?.businessClientName
    : 'Enrollment Alliance';

  return (
    <>
      {mobileData && !siteError ? (
        <div className={classes.wrapper}>
          <div
            style={{ backgroundColor: mobileData?.hexColor }}
            className={classes.header}
          >
            <img
              src={
                mobileData.images.homeLogoUrl ||
                mobileData.images.appLogoUrl ||
                ''
              }
              alt={name}
              className={classes.logo}
            />
          </div>
          {hasOutDatedApp && (
            <Alert severity='warning'>
              This mobile app is out of date and could have broken links or
              buttons that don't work. Please contact your business for the
              latest mobile app.
            </Alert>
          )}
          {!!mobileData.header && (
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'center', color: mobileData.textHexColor }}
                className={classes.headerTextContainer}
              >
                <Typography variant='h6'>{mobileData.header}</Typography>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={1} className={classes.linkItems}>
            {mobileData?.links.map((link: IMobileDataLink, index: number) => {
              return link.enabled ? (
                <Fragment key={`${index + 1}`}>
                  <Grid item xs={6} className={classes.col}>
                    {getMobileAppLink(
                      name,
                      mobileData,
                      link,
                      classes.link,
                      classes.linkIcon,
                      !!mobileData?.secondaryHexColor
                        ? mobileData?.secondaryHexColor
                        : '#000000',
                      classes.name
                    )}
                  </Grid>
                </Fragment>
              ) : null;
            })}
          </Grid>
        </div>
      ) : (
        <div>No Content Available</div>
      )}
    </>
  );
};
const useStyles = makeStyles(() => ({
  '@global': {
    body: {
      margin: 0,
    },
  },
  wrapper: {
    maxWidth: 450,
    margin: '0 auto',
  },
  col: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    boxShadow: '0px 2px 7.2px 0px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#262626',
    padding: '1rem 0',
  },
  logo: {
    width: '60%',
    '@media (min-width: 400px)': {
      width: '50%',
    },
  },
  link: {
    padding: '1.5rem 1rem',
    '@media (min-width: 414px)': {
      padding: '2rem',
    },
  },
  linkIcon: {
    fontSize: '5.25rem',
    color: '#fff',
    '@media (min-width: 414px)': {
      fontSize: '4.25rem',
    },
  },
  linkItems: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '3rem 2rem',
    margin: '-8px',
  },
  loaderWrapper: {
    marginTop: 50,
    '& p, & div': {
      color: ({ primaryColor }: { primaryColor: string }) => primaryColor,
    },
  },
  name: {
    marginTop: 5,
  },
  appBackground: {
    padding: 0,
    maxWidth: '320px',
    height: '100%',
    textAlign: 'center',
  },
  headerTextContainer: {
    fontSize: '4rem',
    textAlign: 'center',
    padding: '1rem 1rem 0',
    marginTop: '.5rem',
  },
}));
