import axios from "../helpers";
import { IMobileBusinessClient } from "../models";

export const getCorrectBitlyLink = async (bitLink: string): Promise<string> => {
  try {
    const res = await axios.post("resolve-url", {
      url: bitLink,
    });
    return res.data;
  } catch (error) {
    // @ts-ignore
    return error && error.response && error.response.data;
  }
};

export const getMobileBusinessClientCustomUrl = async (
  params: string
): Promise<IMobileBusinessClient> => {
  try {
    const res = await axios.get(`mobile${params}`);
    // return testData; // Uncomment for testing mobile app locally
    return res.data; // Comment for testing mobile app locally
  } catch (error) {
    console.log(error);
    // @ts-ignore
    // return testData; // Uncomment for testing mobile app locally
    return error && error.response && error.response.data; // Comment for testing mobile app locally
  }
};

export const getMobileBusinessClient = async (
  id: number,
  params: string
): Promise<IMobileBusinessClient> => {
  try {
    const res = await axios.get(`mobile/${id}${params}`);
    // return testData; // Uncomment for testing mobile app locally
    return res.data; // Comment for testing mobile app
  } catch (error) {
    console.log(error);
    // @ts-ignore
    return error && error.response && error.response.data; // Comment for testing mobile app locally
    // return testData; // Uncomment for testing mobile app locally
  }
};

export const testData = {
  businessClientName: "1 Staging Test Company Logo ",
  brokerName: "Andy's Broker",
  mobileAppEnabled: true,
  hexColor: "#41463f",
  secondaryHexColor: "#f11313",
  header: "",
  textHexColor: "",
  links: [
    {
      businessClientMobileAppLinkId: 307,
      type: "Media",
      enabled: true,
      name: "",
      link: "https://bit.ly/MoOEACOBRAND",
    },
    {
      businessClientMobileAppLinkId: 308,
      type: "Chat",
      enabled: true,
      name: "",
      link: "https://bit.ly/MoOEACOBRAND",
    },
    {
      businessClientMobileAppLinkId: 309,
      type: "FindADoctor",
      enabled: true,
      name: "",
      link: "https://hello.com",
    },
    {
      businessClientMobileAppLinkId: 310,
      type: "BenefitsInfo",
      enabled: true,
      name: "",
      link: "https://www.test3.com",
    },
    {
      businessClientMobileAppLinkId: 311,
      type: "Calendar",
      enabled: true,
      name: "",
      link: null,
    },
    {
      businessClientMobileAppLinkId: 312,
      type: "TalkToAdvisor",
      enabled: true,
      name: "",
      link: "https://www.test3.com",
    },
    {
      businessClientMobileAppLinkId: 331,
      type: "EnrollInBenefits",
      enabled: true,
      name: "",
      link: "https://hello.com",
    },
    {
      businessClientMobileAppLinkId: 332,
      type: "VirtualDoctorVisit",
      enabled: true,
      name: "",
      link: "https://hello.com",
    },
    {
      businessClientMobileAppLinkId: 333,
      type: "PrescriptionDiscounts",
      enabled: true,
      name: "",
      link: "https://hello.com",
    },
    {
      businessClientMobileAppLinkId: 334,
      type: "WellnessIncentives",
      enabled: true,
      name: "",
      link: "https://bit.ly/3IpTjAA",
    },
    {
      businessClientMobileAppLinkId: 335,
      type: "MentalHealth",
      enabled: true,
      name: "",
      link: "https://bit.ly/3IpTjAA",
    },
    {
      businessClientMobileAppLinkId: 336,
      type: "SpeakToConcierge",
      enabled: true,
      name: "",
      link: "https://bit.ly/3txWEJM",
    },
    {
      businessClientMobileAppLinkId: 337,
      type: "Instructions",
      enabled: true,
      name: "Add app to Home Screen",
      link: "https://bit.ly/3txWEJM",
    },
  ],
  images: {
    appLogoType: "BusinessClient",
    appLogoUrl:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6.png",
    app_Logo_Android_Chrome_192x192:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-android-chrome-192x192.png",
    app_Logo_Android_Chrome_512x512:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-android-chrome-512x512.png",
    app_Logo_Apple_Startup_Image_640x1136:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-apple-startup-image-640x1136.png",
    app_Logo_Apple_Startup_Image_750x1334:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-apple-startup-image-750x1334.png",
    app_Logo_Apple_Startup_Image_828x1792:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-apple-startup-image-828x1792.png",
    app_Logo_Apple_Startup_Image_1125x2436:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-apple-startup-image-1125x2436.png",
    app_Logo_Apple_Startup_Image_1242x2208:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-apple-startup-image-1242x2208.png",
    app_Logo_Apple_Startup_Image_1242x2688:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-apple-startup-image-1242x2688.png",
    app_Logo_Apple_Startup_Image_1536x2048:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-apple-startup-image-1536x2048.png",
    app_Logo_Apple_Startup_Image_1668x2224:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-apple-startup-image-1668x2224.png",
    app_Logo_Apple_Startup_Image_1668x2388:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-apple-startup-image-1668x2388.png",
    app_Logo_Apple_Startup_Image_2048x2732:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-apple-startup-image-2048x2732.png",
    app_Logo_Apple_Touch_Icon_180x180:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-apple-touch-icon-180x180.png",
    app_Logo_Favicon_16x16:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-favicon-16x16.png",
    app_Logo_Favicon_32x32:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-favicon-32x32.png",
    app_Logo_Microsoft_Tile_150x150:
      "https://devenalliance4eus01.blob.core.windows.net:443/business-clients/2072/logos/8365335f-c94d-40a6-919b-063c207529c6-microsoft-tile-150x150.png",
    homeLogoType: "Broker",
    homeLogoUrl:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4.png",
    home_Logo_Android_Chrome_192x192:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-android-chrome-192x192.png",
    home_Logo_Android_Chrome_512x512:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-android-chrome-512x512.png",
    home_Logo_Apple_Startup_Image_640x1136:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-apple-startup-image-640x1136.png",
    home_Logo_Apple_Startup_Image_750x1334:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-apple-startup-image-750x1334.png",
    home_Logo_Apple_Startup_Image_828x1792:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-apple-startup-image-828x1792.png",
    home_Logo_Apple_Startup_Image_1125x2436:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-apple-startup-image-1125x2436.png",
    home_Logo_Apple_Startup_Image_1242x2208:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-apple-startup-image-1242x2208.png",
    home_Logo_Apple_Startup_Image_1242x2688:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-apple-startup-image-1242x2688.png",
    home_Logo_Apple_Startup_Image_1536x2048:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-apple-startup-image-1536x2048.png",
    home_Logo_Apple_Startup_Image_1668x2224:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-apple-startup-image-1668x2224.png",
    home_Logo_Apple_Startup_Image_1668x2388:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-apple-startup-image-1668x2388.png",
    home_Logo_Apple_Startup_Image_2048x2732:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-apple-startup-image-2048x2732.png",
    home_Logo_Apple_Touch_Icon_180x180:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-apple-touch-icon-180x180.png",
    home_Logo_Favicon_16x16:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-favicon-16x16.png",
    home_Logo_Favicon_32x32:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-favicon-32x32.png",
    home_Logo_Microsoft_Tile_150x150:
      "https://devenalliance4eus01.blob.core.windows.net:443/brokers/2195/logos/7475e2b1-e742-4115-b72d-cf18a827f0e4-microsoft-tile-150x150.png",
  },
};
