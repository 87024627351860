import Axios, { Method } from 'axios';

let buildConfig = require('../buildSettings');

const axiosInstance = Axios.create({
  baseURL: `${buildConfig.REACT_APP_API_URL}/api/`,
});
const axiosRequest = async (
  method: Method,
  url: string,
  data: object | null,
  options: object,
  params?: any
) => {
  logInfo(null, `axios.js request started: ${url}`);
  try {
    return await axiosInstance({
      method,
      url,
      ...(data ? { data } : {}),
      ...options,
      params,
    });
  } catch (err) {
    logError(err, 'axios.js request failed');
    return Promise.reject(err);
  }
};

export default {
  get: async (url: string, options = {}, params?: any) => {
    return await axiosRequest('get', url, null, options, params);
  },
  post: async (url: string, data = {}, options = {}) => {
    return await axiosRequest('post', url, data, { ...options });
  },
  put: async (url: string, data = {}, options = {}) => {
    return await axiosRequest('put', url, data, { ...options });
  },
  delete: async (url: string, options = {}) => {
    return await axiosRequest('delete', url, null, { ...options });
  },
};

const createSeqLog = async (level: string, details: any, message: string) => {
  if (
    !buildConfig.REACT_APP_SEQ_SERVER_URL ||
    !buildConfig.REACT_APP_SEQ_API_KEY ||
    !buildConfig.REACT_APP_SEQ_ENVIRONMENT
  ) {
    return;
  }

  const options = {
    Application: 'Enrollment Alliance - PWA',
    Environment: buildConfig.REACT_APP_SEQ_ENVIRONMENT,
    ...(details ? { ...details.toJSON(), location: window.location } : details), //TODO:  Determine if this is going to break something in the future.  We are double logging errors in certain places
  };
  try {
    const event = { '@t': new Date(), '@m': message, '@l': level, ...options };
    await fetch(
      `${buildConfig.REACT_APP_SEQ_SERVER_URL}/api/events/raw?clef&apiKey=${buildConfig.REACT_APP_SEQ_API_KEY}`,
      {
        body: JSON.stringify(event),
        method: 'POST',
        // sending the envents as no-cors otherwise we get CORS errors, still works fine https://logs.mwks.io/#/events?filter=Application%20%3D%20'CCBSS%20-%20Self%20Service'
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (error) {
    //console.log(error);
  }
};

export const logInfo = (details: any, message: string) => {
  createSeqLog('Information', details, message);
};

export const logWarn = (details: any, message: string) => {
  createSeqLog('Warning', details, message);
};

export const logError = (details: any, message: string) => {
  createSeqLog('Error', details, message);
};
